/* istanbul ignore file */
import VerticalScroll from './VerticalScroll';

function getVerticalModel() {
  return {
    index: 0,
    displayText: '',
    backgroundColor: '',
    elements: [{
      index: 0,
      top: 0,
      backgroundColor: '',
      fontColor: '',
      text: '',
      visibility: '',
      transition: 0,
    },
    {
      index: 1,
      top: 0,
      backgroundColor: '',
      fontColor: '',
      text: '',
      visibility: '',
      transition: 0,
    },
    {
      index: 2,
      top: 0,
      backgroundColor: '',
      fontColor: '',
      text: '',
      visibility: '',
      transition: 0,
    }],
  };
}

export function initiate(model, indexForSecondText, indexForThirdText) {
  const verticalModel = getVerticalModel();
  verticalModel.elements[0].visibility = 'hidden';
  verticalModel.elements[2].visibility = 'hidden';
  verticalModel.displayText = model.textLines[0].text;
  verticalModel.elements[1].text = model.textLines[0].text;
  verticalModel.elements[1].fontColor = model.textLines[0].fontColor;
  if (model.textLines.length === 1) {
    verticalModel.index = 0;
    verticalModel.elements[2].text = model.textLines[0].text;
    verticalModel.elements[2].fontColor = model.textLines[0].fontColor;
    verticalModel.elements[0].text = model.textLines[0].text;
    verticalModel.elements[0].fontColor = model.textLines[0].fontColor;
  }
  else if (model.textLines.length === 2) {
    verticalModel.index = 1;
    verticalModel.elements[indexForSecondText].text = model.textLines[1].text;
    verticalModel.elements[indexForSecondText].fontColor = model.textLines[1].fontColor;
  }
  else {
    verticalModel.index = 1;
    verticalModel.elements[indexForSecondText].text = model.textLines[1].text;
    verticalModel.elements[indexForSecondText].fontColor = model.textLines[1].fontColor;
    verticalModel.elements[indexForThirdText].text = model.textLines[2].text;
    verticalModel.elements[indexForThirdText].fontColor = model.textLines[2].fontColor;
  }
  verticalModel.backgroundColor = model.textLines[0].backgroundColor;
  return verticalModel;
}

export function getAnimation(model) {
  switch (model.transitionAs) {
    case 0:
      {
        const transitionData = {
          transitionSpeed: 2,
          transitionSpeedFirstElement: 2,
          transitionSpeedSecondElement: 0,
          getTransitionsIndex: index => {
            const nextIndex = index - 1;
            if (nextIndex < 0)
              return 2;
            return nextIndex;
          },
          getElementToRepresentNextText: (topElementRef, bottomElementRef) => bottomElementRef,
          indexForSecondText: 0,
          indexForThirdText: 2,
        };
        return [VerticalScroll, initiate(model, 2, 0), transitionData];
      }
    case 1:
      {
        const transitionData = {
          transitionSpeed: 2,
          transitionSpeedFirstElement: 0,
          transitionSpeedSecondElement: 2,
          getTransitionsIndex: index => {
            const nextIndex = index + 1;
            if (nextIndex > 2)
              return 0;
            return nextIndex;
          },
          getElementToRepresentNextText: (topElementRef, bottomElementRef) => topElementRef,
          indexForSecondText: 2,
          indexForThirdText: 0,
        };
        return [VerticalScroll, initiate(model, 0, 2), transitionData];
      }
    default:
      {
        const transitionData = {
          transitionSpeed: 2,
          transitionSpeedFirstElement: 2,
          transitionSpeedSecondElement: 0,
          getTransitionsIndex: index => {
            const nextIndex = index - 1;
            if (nextIndex < 0)
              return 2;
            return nextIndex;
          },
          getElementToRepresentNextText: (topElementRef, bottomElementRef) => bottomElementRef,
          indexForSecondText: 2,
          indexForThirdText: 0,

        };
        return [VerticalScroll, initiate(model, 2, 0), transitionData];
      }
  }
}