import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Bar.module.scss';
import { getAnimation } from './effects/library';

/* istanbul ignore file */
export function getOrDefault(value, defaultValue) {
  if (!value)
    return defaultValue;
  return value;
}

/* istanbul ignore file */
export function getDefaultValuesIfNeeded(model) {
  return model.map(m => {
    return {
      ...m,
      fontColor: getOrDefault(m.fontColor, '#FFFFFF'),
      backgroundColor: getOrDefault(m.backgroundColor, '#000000'),
    };
  });
}

const Bar = ({ model }) => {
  model.textLines = getDefaultValuesIfNeeded(model.textLines.filter(m => m.visible !== false).sort(function (a, b) {
    return a.displayIndex - b.displayIndex;
  }));
  const [Animation, data, transitionData] = getAnimation(model);
  return (
    <div className={classNames('usp-bar-content-block', styles.bar)}>
      <Animation
        model={data}
        textLines={model.textLines}
        rotationDelay={model.rotationDelay}
        transitionData={transitionData}
      />
    </div>
  );
};

Bar.propTypes = {
  model: PropTypes.shape({
    textLines: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      fontColor: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
    })),
    rotationDelay: PropTypes.number.isRequired,
    transitionAs: PropTypes.number.isRequired,
  }),
};

export default Bar;