exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.25.43\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".UspBarContentBlock__Bar_bar{width:100%;height:100%;display:flex}", ""]);

// exports
exports.locals = {
	"bar": "UspBarContentBlock__Bar_bar"
};